export const BusinessName1="N MENSAH"
export const BusinessName2="CAR RENTALS"
export const Fullname="RMB CAR RENTALS"
export const Logo=""
export const Whatsapp="https://api.whatsapp.com/send?phone=+233244491738&text=Hello i need a car to rent"
export const Facebook="https://web.facebook.com/NMensahRentals"
export const Instagram="https://www.instagram.com/nmensahcarrentals/"
export const xTwitter=""
export const Mobile="0244491738-+0200238921-+233322009382"
export const Emails="Nyawmensah27@gmail.com"
export const Location="Kumasi, Oforikrom-Tech, Accra main road, Kumasi"
//export const Location="Asokwa Near Pizzaman"
// export const Motto="ALWAYS RELIABLE,NO ROOM FOR DISAPPOINTMENT"
export const Motto="Your Trusted Choice for Reliable Car Rentals"
export const SubDetails="When it comes to affordability,safety and quality service,We are comparatively the best car rental company nation wide"